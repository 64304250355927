// Copyright 1999-2021. Plesk International GmbH. All rights reserved.

/* eslint-disable camelcase */

import { createElement, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useQuery, gql } from '@apollo/client';
import { Translate, Form, SectionItem, FormFieldText, FormFieldPassword, FormFieldSelect, SelectOption, Link } from '@plesk/ui-library';
import { withLoginLayout } from './LoginLayout';
import { usePreferencesToggle } from './CookiePolicy';

const Login = ({ values, availableLocales, params }, { locale }) => {
    const [, toggleCookiePolicyPreferences] = usePreferencesToggle();

    const { data: { config } = {} } = useQuery(gql`
        {
            config {
                gdpr {
                    cookieSettings {
                        loginFormEntrypointEnabled
                    }
                }
            }
        }
    `);

    const handleLocaleChange = value => {
        document.querySelector('input[name="locale_id"]').value = value;
        document.getElementById('form-login').submit();
    };

    const handleSubmit = values => {
        if (values.login_name === '' || values.passwd === '') {
            // eslint-disable-next-line no-alert
            alert(locale.lmsg('enterLoginAndPasswd'));
            return;
        }
        document.getElementById('form-login').submit();
    };

    return (
        <Fragment>
            <Form
                id="form-login"
                method="post"
                applyButton={false}
                cancelButton={false}
                submitButton={{
                    name: 'send',
                    children: <Translate content="loginButtonLabel" />,
                    type: 'submit',
                    fill: true,
                }}
                onSubmit={handleSubmit}
                vertical
                values={values}
                className="login-page__form"
            >
                <div id="loginSection">
                    <FormFieldText
                        name="login_name"
                        label={<Translate content="loginLabel" />}
                        autoFocus
                        size="fill"
                    />
                    <FormFieldPassword
                        name="passwd"
                        label={<Translate content="passwdLabel" />}
                        hideGenerateButton
                        hidePasswordMeter
                        size="fill"
                    />
                    <FormFieldSelect
                        name="locale_id"
                        label={<Translate content="localeLabel" />}
                        size="fill"
                        onChange={handleLocaleChange}
                    >
                        <SelectOption value="default" label="default"><Translate content="defaultLocale" /></SelectOption>
                        {availableLocales.map(({ code, name, dir }) => (
                            <SelectOption key={code} value={code} dir={dir}>{name}</SelectOption>
                        ))}
                    </FormFieldSelect>
                </div>

                <SectionItem>
                    <Link href={'/get_password.php'.concat(values.locale_id ? '?locale='.concat(values.locale_id) : '')}><Translate content="forgotPasswordLabel" /></Link>
                </SectionItem>

                {document.getElementById('forgery_protection_token') ? (
                    <input
                        type="hidden"
                        name="forgery_protection_token"
                        value={document.getElementById('forgery_protection_token').content}
                    />
                ) : null}

                {Object.keys(params).map(name => (
                    <input
                        key={name}
                        name={name}
                        value={params[name]}
                        type="hidden"
                    />
                ))}
            </Form>
            {config?.gdpr?.cookieSettings?.loginFormEntrypointEnabled && (
                <p><a onClick={() => toggleCookiePolicyPreferences(true)}><Translate content="cookies" /></a></p>
            )}
        </Fragment>
    );
};

Login.propTypes = {
    values: PropTypes.shape({
        login_name: PropTypes.string,
        passwd: PropTypes.string,
        locale_id: PropTypes.string,
    }),
    availableLocales: PropTypes.array.isRequired,
    params: PropTypes.object,
};

Login.defaultProps = {
    params: {},
    values: {
        locale_id: 'default',
    },
};

Login.contextTypes = {
    locale: PropTypes.object,
};

export default withLoginLayout(Login);
