// Copyright 1999-2021. Plesk International GmbH. All rights reserved.

import { Component } from './component';
import namespace from './namespace';

/**
 * Creates a new Component from the specified config object using the config object's
 * `componentType` to determine the class to instantiate.
 * @param {Object} config A configuration object for the Component you wish to create.
 * @returns {Component} The newly instantiated Component.
 */
export default config => {
    if (!config.componentType || config instanceof Component) {
        return config;
    }

    const Cls = typeof config.componentType === 'string'
        ? namespace(config.componentType, false)
        : config.componentType;
    if (!Cls) {
        throw new Error(`Unrecognized class name: ${config.componentType}`);
    }
    return new Cls(config);
};
