// Copyright 1999-2021. Plesk International GmbH. All rights reserved.

import { init, configureScope, Integrations } from '@sentry/browser';
import { Dedupe } from '@sentry/integrations';
import { Integrations as TracingIntegrations } from '@sentry/tracing';

export default ({ sentry }) => {
    if (!sentry) {
        return;
    }

    if (window.navigator.userAgent.match(/jsdom/)) {
        return;
    }

    const { user = null, tags = {}, environment, ...options } = sentry;
    init({
        integrations: [
            new Integrations.Breadcrumbs({ console: false }),
            new Dedupe(),
            new TracingIntegrations.BrowserTracing({
                beforeNavigate: context => ({
                    ...context,
                    name: location.pathname
                        .replace(/\/[a-f0-9]{8}-[a-f0-9]{4}-[0-5][a-f0-9]{3}-[089ab][a-f0-9]{3}-[a-f0-9]{12}/ig, '/<guid>')
                        .replace(/\/[a-f0-9]{32}/g, '/<hash>')
                        .replace(/\/\d+/g, '/<digits>'),
                }),
            }),
        ],
        beforeBreadcrumb(breadcrumb) {
            if (breadcrumb.type === 'http' && breadcrumb.data.url.includes('firehose.us-west-2.amazonaws.com')) {
                return null;
            }

            return breadcrumb;
        },
        ignoreErrors: [
            'ResizeObserver loop',
            'ApiFetchError:',
            'whmcs_user_id is not defined',
            '__renderMessageNode is not defined',
            'Module build failed',
            'jQuery is not defined',
            'safari-extension://',
            'moz-extension://',
            'chrome-extension://',
        ],
        denyUrls: [
            // TODO EXTPLESK-1671: [Help Center] Not compatible with Plesk 18.0
            '/modules/help-center/global.js',
            // TODO PPP-51300: Image preview extension does not work
            '/modules/image-preview/image-preview.js',
            // TODO EXTPLESK-2342 [Speed Kit] Sentry pollution when Plesk session is expired
            '/modules/baqend/script.js',
            // TODO EXTPLESK-2242: Legacy Addendio - WordPress Plugins and Themes generates errors on login page
            '/modules/addendio-wordpress/global.js',
        ],
        tracesSampleRate: environment === 'development' ? 0.01 : 0,
        environment,
        ...options,
    });
    configureScope(scope => {
        scope.setUser(user);
        for (const tag in tags) {
            scope.setTag(tag, tags[tag]);
        }
    });
};
