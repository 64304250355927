// Copyright 1999-2021. Plesk International GmbH. All rights reserved.

import { Component } from './component';
import render from './render';

import './hint.less';

/**
 * @class Hint
 * @extends Component
 *
 * Example usage:
 *
 *     @example
 *     new Hint({
 *         renderTo: document.body,
 *         hint: 'This is where you manage accounts of your hosting service customers: ' +
 *             'create accounts along with service subscriptions, manage existing customers, ' +
 *             'and remove accounts. To administer websites, mail or any other services provided ' +
 *             'to a customer, go to their Customer Panel by clicking the link in the right column of the list.',
 *         expandable: true
 *     });
 */
export class Hint extends Component {
    /**
     * @cfg {String} hint
     */
    /**
     * @cfg {Boolean} [expandable=false]
     */

    _initConfiguration(config) {
        super._initConfiguration({ tag: 'p', ...config });
    }

    _initComponentElement() {
        super._initComponentElement();

        this.setHint(
            this._getConfigParam('hint', '')
        );

        if (this._getConfigParam('expandable', false)) {
            const contentElement = this._componentElement;
            const componentElement = new Element('div', { class: 'screen-description' });
            const wrapElement = new Element('div', { class: 'screen-description-wrap' });
            const btnShowMore = new Element('span', { class: 'screen-description-control' });
            btnShowMore.addEventListener('click', event => {
                event.target.closest('.screen-description').classList.add('expanded');
            });
            render(wrapElement, contentElement);
            render(wrapElement, btnShowMore);
            render(componentElement, wrapElement);
            this._componentElement = componentElement;
            this._screenControl = () => {
                if (Element.getWidth(componentElement) > Element.getWidth(wrapElement)) {
                    btnShowMore.classList.add('off');
                } else {
                    btnShowMore.classList.remove('off');
                }
            };
        }
    }

    _addEvents() {
        super._addEvents();

        if (this._screenControl) {
            window.addEventListener('resize', this._screenControl);
            this._screenControl();
        }
    }

    /**
     * Update hint text.
     * @param {String} hint
     */
    setHint(hint) {
        this._componentElement.innerHTML = hint;
    }
}
