// Copyright 1999-2021. Plesk International GmbH. All rights reserved.

import { createElement, createPortal, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { escapeHtml } from 'jsw';
import Status from './Status';

const updateDocumentTitle = pageTitle => {
    const mainTitle = escapeHtml(document.title.split(' - ').pop());

    const documentTitle = document.querySelector('title');
    if (documentTitle) {
        documentTitle.innerHTML = (
            pageTitle ? `${pageTitle.stripTags()} - ${mainTitle}` : mainTitle
        );
    }
};

const renderBottomAnchor = () => {
    const bottomAnchor = document.getElementById('bottomAnchor');

    if (bottomAnchor) {
        bottomAnchor.innerHTML = new Date().getTime();
    }
};

const PageContent = ({
    pageTitle,
    sidebar,
    children,
}) => {
    useEffect(() => {
        if (pageTitle !== null) {
            updateDocumentTitle(pageTitle);
        }
    }, [pageTitle]);

    renderBottomAnchor();

    const sidebarEl = document.querySelector('.b-content-side > div');
    return (
        <Fragment>
            <Status />
            {children}
            {sidebar && sidebarEl ? createPortal(sidebar, sidebarEl) : null}
        </Fragment>
    );
};

PageContent.propTypes = {
    pageTitle: PropTypes.string,
    sidebar: PropTypes.element,
    children: PropTypes.element,
};

PageContent.defaultProps = {
    pageTitle: null,
    sidebar: null,
    children: null,
};

export const withPageContent = (Component, Sidebar) => {
    // eslint-disable-next-line react/prop-types
    const Wrapper = ({ pageTitle, breadcrumbs, ...props }) => (
        <PageContent
            pageTitle={pageTitle}
            sidebar={Sidebar ? <Sidebar {...props} /> : undefined}
        >
            <Component {...props} />
        </PageContent>
    );
    Wrapper.displayName = `withPageContent(${Component.displayName || Component.name || 'Component'})`;
    Wrapper.WrappedComponent = Component;

    return Wrapper;
};

export default PageContent;
