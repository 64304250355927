// Copyright 1999-2021. Plesk International GmbH. All rights reserved.

export default (url, params) => {
    if ('string' === typeof params) {
        const splitUrl = url.split('?', 2);
        params = params.startsWith('/') || params.startsWith('?') ? params : `?${params}`;
        const splitParams = params.split('?', 2);
        url = splitUrl[0].replace(/\/+$/, '') + splitParams[0];
        params = {
            ...(splitUrl[1] || '').toQueryParams(),
            ...(splitParams[1] || '').toQueryParams(),
        };
    }

    if (Object.prototype.toString.call(params) === '[object Object]') {
        params = Object.keys(params).map(function (name) {
            if ('undefined' === typeof params[name]) {
                return name;
            }
            return `${name}=${encodeURIComponent(params[name])}`;
        }).join('&');
    }

    if (!params) {
        return url;
    }
    return url + (-1 !== url.indexOf('?') ? '&' : '?') + params;
};
