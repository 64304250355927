// Copyright 1999-2021. Plesk International GmbH. All rights reserved.

import { captureException } from '@sentry/browser';

const HIGH = 'high';
const NORMAL = 'normal';
const LOW = 'low';

export const priority = {
    [HIGH]: HIGH,
    [NORMAL]: NORMAL,
    [LOW]: LOW,
};

const onReadyEvents = {};
let firedOnReady = false;

/**
 * Adds a listener to be notified when the document is ready (before onload and before images are loaded).
 * @param {String|Function} [priority]
 * @param {Function} callback The method to call.
 */
export default (priority, callback) => {
    if ('function' === typeof priority) {
        callback = priority;
        priority = NORMAL;
    }

    const wrappedCallback = () => {
        try {
            callback();
        } catch (e) {
            captureException(e);
            // eslint-disable-next-line no-console
            console && console.error(e);
        }
    };

    if (firedOnReady) {
        wrappedCallback();
        return;
    }

    if (!onReadyEvents[priority]) {
        onReadyEvents[priority] = [];
    }

    onReadyEvents[priority].push(wrappedCallback);
};

document.onreadystatechange = () => {
    if (document.readyState === 'interactive' && !firedOnReady) {
        firedOnReady = true;
        const predefinedPriorities = [LOW, NORMAL, HIGH];
        Object.keys(onReadyEvents)
            .sort((a, b) => predefinedPriorities.indexOf(b) - predefinedPriorities.indexOf(a))
            .forEach(priority => {
                onReadyEvents[priority].forEach(Function.prototype.call, Function.prototype.call);
            });
    }
};
