// Copyright 1999-2021. Plesk International GmbH. All rights reserved.

import { createElement, useEffect } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import RouteRequire from './RouteRequire';
import PageContent from './PageContent';
import { useDataContext } from './DataContext';
import routes from '../routes';

const PhpPage = withRouter(({ location }) => {
    const { data: { withSecondary } } = useDataContext();
    useEffect(() => () => {
        if (location.pathname.startsWith('/smb/log-file/browser')) {
            // TODO Need to rework this page to client routing and move this code to it unmount hook
            Jsw.getComponent('log-browser')?.remove();
        }
    }, [location]);

    return (
        <PageContent withSecondary={withSecondary} />
    );
});

const Routes = () => (
    <Switch>
        {routes.map(({ path, ...props }) => (
            <RouteRequire key={path} path={path} {...props} />
        ))}
        <Route path="*">
            <PhpPage />
        </Route>
    </Switch>
);

export default Routes;
