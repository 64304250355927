// Copyright 1999-2021. Plesk International GmbH. All rights reserved.

import { createElement } from 'react';
import classNames from 'classnames';
import { Heading, Paragraph, Button, Link } from '@plesk/ui-library';
import PropTypes from 'prop-types';
import { Locale } from 'jsw';
import { PLESK_COOKIES } from './urls';

const Translate = Locale.getTranslate('components.cookie-policy.info');

const Info = ({ onSave, onPreferencesToggle, contentAlignment }) => {
    const handleAcceptClick = () => {
        onSave({ analytics: true, marketing: true });
        onPreferencesToggle(false);
    };

    const handlePreferencesClick = () => {
        onPreferencesToggle(true);
    };

    return (
        <div
            className={classNames('cookie-policy-info', {
                [`cookie-policy-info--${contentAlignment}`]: contentAlignment,
            })}
        >
            <div className="cookie-policy-info__inner">
                <Heading level={3} className="cookie-policy-info__title">
                    <Translate content="title" />
                </Heading>
                <Paragraph>
                    <Translate content="necessaryCookiesMessage" />
                </Paragraph>
                <Paragraph>
                    <Translate
                        content="message"
                        params={{
                            cookieInformationLink: (
                                <Link
                                    href={PLESK_COOKIES}
                                    target="_blank"
                                    className="cookie-policy-info__link"
                                >
                                    <Translate
                                        content="cookieInformationLink"
                                    />
                                </Link>
                            ),
                        }}
                    />
                </Paragraph>
                <div className="cookie-policy-info__buttons">
                    <Button
                        className="cookie-policy-info__button"
                        data-type="cookie-policy-info-accept-button"
                        onClick={handleAcceptClick}
                    >
                        <Translate content="acceptButton" />
                    </Button>
                    <Button
                        className="cookie-policy-info__button"
                        data-type="cookie-policy-info-preferences-button"
                        onClick={handlePreferencesClick}
                    >
                        <Translate content="preferencesButton" />
                    </Button>
                </div>
            </div>
        </div>
    );
};

Info.propTypes = {
    onSave: PropTypes.func.isRequired,
    onPreferencesToggle: PropTypes.func.isRequired,
    contentAlignment: PropTypes.oneOf(['center']),
};

Info.defaultProps = {
    contentAlignment: undefined,
};

export default Info;
