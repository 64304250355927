// Copyright 1999-2021. Plesk International GmbH. All rights reserved.

import { Component } from './component';

/**
 * Component with custom inner html.
 *
 * Example usage:
 *
 *     @example
 *     new Box({
 *         renderTo: document.body,
 *         html: '<h1>Heading 1</h1><p>Paragraph</p>'
 *     });
 */
export class Box extends Component {
    /**
     * @cfg {String} html Inner html of the box.
     */

    _initComponentElement() {
        super._initComponentElement();

        this._componentElement.innerHTML = this._getConfigParam('html', '');
    }
}
