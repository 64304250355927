// Copyright 1999-2021. Plesk International GmbH. All rights reserved.

import { escapeHtml } from 'jsw';

class Tooltip {
    // tooltip to show timeout
    showTimeout = 0.55;

    // minimum viewport delta
    minDelta = 5;

    // horisonatl offset from cursor
    xOffsetLeft = -5;

    // horisonatl offset from cursor
    xOffsetRight = 5;

    // vertical offset from cursor
    yOffsetTop = 1;

    // vertical offset from cursor
    yOffsetBottom = 13;

    // tooltip element
    tooltip = null;

    // tooltip generator
    tooltipEventElement = null;

    // tooltip mouse move observer
    tooltipMouseMoveObserver = null;

    // show status
    tooltipVisible = false;

    // show timeout
    tooltipShowTimeout = null;

    constructor(element) {
        this.element = element;
        this.tooltip = this.createTooltipElement();
        this.tooltipMouseMoveObserver = this.onMouseMove.bind(this);
        this.tooltipMouseOutObserver = this.onMouseOut.bind(this);
    }

    set(event, entries) {
        this.tooltipX = event.clientX + (window.scrollX || window.pageXOffset);
        this.tooltipY = event.clientY + (window.scrollY || window.pageYOffset);
        this.tooltipEventElement = event.target;

        // hack observe mouse move on tooltip actor
        if (!this.tooltipEventElement.classList.contains('tootlipObserved')) {
            this.tooltipEventElement.classList.add('tootlipObserved');
            this.tooltipEventElement.addEventListener('mousemove', this.tooltipMouseMoveObserver);
            this.tooltipEventElement.addEventListener('mouseout', this.tooltipMouseOutObserver);
        }

        let data = '';
        $A(entries).each(entry => {
            switch (entry.type) {
                case 'string':
                    data += `${escapeHtml(entry.string)}<br>`;
                    break;
            }
        });
        this.tooltip.innerHTML = data;
        this.show();
    }

    onMouseMove(event) {
        this.tooltipX = event.clientX + (window.scrollX || window.pageXOffset);
        this.tooltipY = event.clientY + (window.scrollY || window.pageYOffset);

        if (this.tooltipVisible) {
            this.showAction(this.tooltip);
        }
    }

    onMouseOut() {
        this.unset();
    }

    unset() {
        this.hide();
    }

    showTimed() {
        // show new message
        if (false === this.tooltipShowTimeout) {
            this.finishShowTimed();
            return;
        }

        if (null !== this.tooltipShowTimeout) {
            this.cancelShowTimed();
        }

        this.tooltipShowTimeout = new PeriodicalExecuter(this.finishShowTimed.bind(this), this.showTimeout);
    }

    cancelShowTimed() {
        if (this.tooltipShowTimeout) {
            this.tooltipShowTimeout.stop();
            this.tooltipShowTimeout = null;
        }
    }

    finishShowTimed() {
        this.cancelShowTimed();
        this.tooltipShowTimeout = false;
        this.showAction(this.tooltip);
    }

    show() {
        this.showTimed();
    }

    getShowPosition() {
        let x;
        let y;

        const tDim = Element.getDimensions(this.tooltip);
        const vDim = document.viewport.getDimensions();
        const vScr = document.viewport.getScrollOffsets();

        const freeRight = vDim.width + vScr.left - this.tooltipX;
        const freeLeft = this.tooltipX - vScr.left;
        const freeTop = this.tooltipY - vScr.top;
        const freeBottom = vDim.height + vScr.top - this.tooltipY;

        // apply better x
        if (freeRight > tDim.width + this.minDelta + this.xOffsetRight) {
            x = this.tooltipX + this.xOffsetRight;
        } else if (freeLeft > tDim.width + this.minDelta + this.xOffsetLeft) {
            x = this.tooltipX - tDim.width - this.xOffsetLeft;
        } else {
            x = this.tooltipX - ((tDim.width + this.xOffsetLeft) / 2);
        }

        // apply better y
        if (freeBottom > tDim.height + this.minDelta + this.yOffsetBottom) {
            y = this.tooltipY + this.yOffsetBottom;
        } else if (freeTop > tDim.height + this.minDelta + this.yOffsetTop) {
            y = this.tooltipY - tDim.height - this.yOffsetTop;
        } else {
            y = this.tooltipY - ((tDim.height + this.yOffsetTop) / 2);
        }

        if (x < 0) {
            x = this.minDelta;
        }

        return { x, y };
    }

    showAction(tooltip) {
        const position = this.getShowPosition();
        // change position
        tooltip.style.position = 'absolute';
        tooltip.style.top = `${position.y}px`;
        tooltip.style.left = `${position.x}px`;

        // show
        tooltip.style.display = '';

        // first show of tooltip?
        const firstShow = !this.tooltipVisible;
        this.tooltipVisible = true;
        if (firstShow) {
            // update position with correct tooltip size
            this.showAction(tooltip);
        }
    }

    hide() {
        this.cancelShowTimed();
        this.tooltipShowTimeout = null;
        this.hideAction(this.tooltip);
    }

    hideAction(tooltip) {
        this.tooltipVisible = false;
        tooltip.style.display = 'none';
    }

    createTooltipElement() {
        const tooltip = document.createElement('div');
        tooltip.classList.add('tooltip');
        this.hideAction(tooltip);
        document.body.appendChild(tooltip);
        return tooltip;
    }
}

export default Tooltip;
