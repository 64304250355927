// Copyright 1999-2021. Plesk International GmbH. All rights reserved.

import { createElement, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Measure from 'react-measure';
import { Layout, Columns, Column } from '@plesk/ui-library';
import PageHeader from '../PageHeader';
import CookiePolicy from '../CookiePolicy';
import LicenseStatus from './LicenseStatus';
import PageSidebar from './PageSidebar';
import PageFooter from './PageFooter';
import PageContentHeader from './PageContentHeader';
import { useDataContext } from '../DataContext';
import { useLocalStorage, useMediaQuery } from '../../../../common/hooks';

const PageLayout = ({ children }) => {
    const { data: {
        baseUrl,
        logo,
        showFrames,
        pageHeader,
        pageSidebar,
        pageContentHeader,
        withSecondary,
        width,
    } } = useDataContext();

    const [isClosedOnDesktop, setClosedOnDesktop] = useLocalStorage('isSidebarClosed');
    const [isClosedInResponsive, setClosedInResponsive] = useState(true);
    const sidebarCollapsed = [isClosedInResponsive && 'responsive', (isClosedOnDesktop === 'true') && 'desktop'].filter(Boolean);
    const isResponsive = useMediaQuery('(max-width: 1022px)');
    const isClosed = sidebarCollapsed.includes(isResponsive ? 'responsive' : 'desktop');

    if (baseUrl) {
        global.Jsw.baseUrl = baseUrl;
    }

    const handleToggleSidebar = (collapsed, mode) => {
        switch (mode) {
            case 'responsive':
                setClosedInResponsive(collapsed);
                break;
            case 'desktop':
                setClosedOnDesktop(collapsed ? 'true' : 'false');
                break;
        }
    };

    const [headerHeight, setHeaderHeight] = useState(0);
    const [headerAddonHeight, setHeaderAddonHeight] = useState(0);
    const [stickyTop, setStickyTop] = useState(0);

    useEffect(() => {
        const top = headerHeight + headerAddonHeight
            + parseInt(getComputedStyle(document.querySelector('.pul-layout__main-inner')).paddingTop)
            + (document.getElementById('classic-mode-navigation')?.getBoundingClientRect().height || 0);
        setStickyTop(top);
    }, [headerHeight, headerAddonHeight]);

    return (
        <Layout
            id="page"
            width={width}
            header={showFrames && pageHeader ? (
                <Measure bounds onResize={({ bounds }) => setHeaderHeight(bounds.height)}>
                    {({ measureRef }) => (
                        <div ref={measureRef}>
                            <PageHeader {...pageHeader} />
                        </div>
                    )}
                </Measure>
            ) : undefined}
            headerAddon={showFrames && pageHeader ? (
                <Measure bounds onResize={({ bounds }) => setHeaderAddonHeight(bounds.height)}>
                    {({ measureRef }) => (
                        <div ref={measureRef}>
                            <CookiePolicy />
                            {pageHeader.licenseStatus ? <LicenseStatus {...pageHeader.licenseStatus} /> : null}
                        </div>
                    )}
                </Measure>
            ) : undefined}
            onSidebarToggle={handleToggleSidebar}
            sidebar={showFrames && pageSidebar ? (
                <PageSidebar
                    logo={logo}
                    isClosed={isClosed}
                    onNodeClick={isResponsive ? () => setClosedInResponsive(true) : undefined}
                    {...pageSidebar}
                />
            ) : undefined}
            sidebarCollapsed={sidebarCollapsed}
            sidebarType="folded"
            footer={showFrames ? (
                <PageFooter />
            ) : undefined}
            contentHeader={pageContentHeader ? (
                <PageContentHeader {...pageContentHeader} />
            ) : null}
        >
            <div className="b-content">
                <Columns gap="md" vertical={740} data-type="page-content" className="b-content-wrap">
                    <Column fill id="content-body" className="b-content-main">
                        <div id="main" style={withSecondary ? { position: 'sticky', top: `${stickyTop}px` } : undefined}>
                            {children}
                        </div>
                    </Column>
                    {withSecondary ? (
                        <Column width={256} className="b-content-side">
                            <div style={{ position: 'sticky', top: `${stickyTop}px` }} />
                        </Column>
                    ) : null}
                </Columns>
            </div>
        </Layout>
    );
};

PageLayout.propTypes = {
    children: PropTypes.any,
};

PageLayout.defaultProps = {
    children: undefined,
};

export default PageLayout;
