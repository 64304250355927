// Copyright 1999-2021. Plesk International GmbH. All rights reserved.

/* eslint-disable react/require-render-return */

import { Component } from './component';
import { DynamicPopupHint } from './dynamic-popup-hint';
import render from './render';

import './password-meter.less';

const verdictClasses = {
    verdictNone: 'verdictNone',
    verdictVeryWeak: 'password-strength-very-weak',
    verdictWeak: 'password-strength-weak',
    verdictMediocre: 'password-strength-medium',
    verdictStrong: 'password-strength-strong',
    verdictStronger: 'password-strength-very-strong',
};

const rules = {
    passwordTooShort(passwd) {
        return passwd.length < 5 ? -1 : 0;
    },

    passwordLength(passwd) {
        if (passwd.length < 5) {
            return 3;
        }
        if (passwd.length > 4 && passwd.length < 8) {
            return 6;
        }
        if (passwd.length > 7 && passwd.length < 16) {
            return 12;
        }
        return 18;
    },

    lettersLowerCase(passwd) {
        // [verified] at least one lower case letter
        return passwd.match(/[a-z]/) ? 1 : -1;
    },

    lettersUpperCase(passwd) {
        // [verified] at least one upper case letter
        return passwd.match(/[A-Z]/) ? 5 : -1;
    },

    numbers1(passwd) {
        // [verified] at least one number
        return passwd.match(/\d+/) ? 5 : -1;
    },

    numbers3(passwd) {
        // [verified] at least three numbers
        return passwd.match(/(.*[0-9].*[0-9].*[0-9])/) ? 5 : -1;
    },

    specialChar1(passwd) {
        // [verified] at least one special character
        return passwd.match(/[!@#$%^&*?_~]/) ? 5 : -1;
    },

    specialChar2(passwd) {
        // [verified] at least two special characters
        return passwd.match(/(.*[!@#$%^&*?_~].*[!@#$%^&*?_~])/) ? 5 : -1;
    },

    comboUpperAndLower(passwd) {
        // [verified] both upper and lower case
        return passwd.match(/([a-z].*[A-Z])|([A-Z].*[a-z])/) ? 2 : -1;
    },

    comboLettersAndNumbers(passwd) {
        // [verified] both letters and numbers
        return passwd.match(/([a-zA-Z])/) && passwd.match(/([0-9])/) ? 2 : -1;
    },

    comboLettersNumbersSpecial(passwd) {
        // [verified] letters, numbers, and special characters
        return passwd.match(/([a-zA-Z0-9].*[!@#$%^&*?_~])|([!@#$%^&*?_~].*[a-zA-Z0-9])/) ? 2 : -1;
    },
};

export class PasswordMeter extends Component {
    _initConfiguration(config) {
        super._initConfiguration({
            tag: 'span',
            cls: 'password-strength',
            ...config,
        });
        this._passwordElement = document.getElementById(this._getConfigParam('observe', null));
        this._passwordElement.addEventListener('keyup', this._onChange.bind(this));
        // Temporary workaround for custom prototype's event 'plesk:passwordGenerated'
        this._passwordElement.addEventListener('dataavailable', this._onChange.bind(this));
        this._passwordElement.addEventListener('blur', this._onBlur.bind(this));
        this._requiredStrength = this._getConfigParam('strength', -1);
    }

    _initComponentElement() {
        super._initComponentElement();

        this._hintContainer = document.createElement('span');
        this._hintContainer.className = 'hint-inline hint-info';
        this._hint = document.createElement('span');
        this._hint.innerHTML = this.lmsg('hintInfo');
        this._hintContainer.appendChild(this._hint);

        this._progress = document.createElement('i');
        render(this._componentElement, this._progress);

        this._verdict = document.createElement('b');
        render(this._componentElement, this._verdict);
    }

    setProgress(value) {
        this._progress.setAttribute('style', `width: ${value}%`);
    }

    _onBlur() {
        const password = this._passwordElement.value;
        if ('' !== password) {
            const result = this._applyRules(password);
            this._updateError(result.score);
        }
    }

    _onChange() {
        const password = this._passwordElement.value;
        const result = this._applyRules(password);
        this._updateVisibility();
        this._updateProgress(result.score);
        this._updateHint(result.unusedRules);
        this._updateVerdict(result.score);
        this._updateColor(result.score);
        if ('' !== password) {
            this._updateError(result.score);
        }
    }

    _updateVisibility() {
        if (this._passwordElement.value === '') {
            this._componentElement.style.display = 'none';
            this._hintContainer.style.display = 'none';
        } else {
            this._componentElement.style.display = '';
            this._hintContainer.style.display = '';
        }
    }

    _applyRules(passwd) {
        const unusedRules = [];
        let sum = 0;
        Object.keys(rules).forEach(rule => {
            const mark = rules[rule](passwd);
            if (mark < 0) {
                unusedRules.push({ rule, value: -mark });
            } else {
                sum += mark;
            }
        });

        return {
            score: sum,
            unusedRules,
        };
    }

    _updateError(score) {
        const rowElement = this._passwordElement.closest('.form-row');
        if (!rowElement) {
            return;
        }
        if (score <= this._requiredStrength) {
            rowElement.classList.add('error');
            rowElement.querySelector('.field-errors').innerHTML = `<span class="error-hint">${this.lmsg('errorHint')}</span>`;
            rowElement.querySelector('.field-errors').style.display = '';
        } else {
            rowElement.classList.remove('error');
            rowElement.querySelector('.field-errors').innerHTML = '';
            rowElement.querySelector('.field-errors').style.display = 'none';
        }
    }

    _updateColor(score) {
        const verdict = this._getVerdict(score);
        const newClass = verdictClasses[verdict];
        if (this._oldClass === newClass) {
            return;
        }
        if (this._oldClass) {
            this._componentElement.classList.remove(this._oldClass);
        }
        this._componentElement.classList.add(newClass);
        this._oldClass = newClass;
    }

    _updateHint(unusedRules) {
        let description = '';
        if (unusedRules.length) {
            description = `${this.lmsg('description')}<br/><ul>`;
            for (let i = 0; i < unusedRules.length && i < 3; ++i) {
                description += `<li>${this.lmsg(unusedRules[i].rule)}</li>`;
            }
            description += '</ul>';
        } else {
            description += this.lmsg('yourPasswordIsStrong');
        }

        this._tooltip.setContent(description);
    }

    _updateVerdict(score) {
        this._verdict.innerHTML = this.lmsg(this._getVerdict(score));
    }

    _updateProgress(score) {
        const value = (Math.min(score, 42) / 42) * 100;
        this.setProgress(value);
    }

    _getVerdict(score) {
        if (score < 1) {
            return 'verdictNone';
        }
        if (score < 16) {
            return 'verdictVeryWeak';
        }
        if (score > 15 && score < 25) {
            return 'verdictWeak';
        }
        if (score > 24 && score < 35) {
            return 'verdictMediocre';
        }
        if (score > 34 && score < 45) {
            return 'verdictStrong';
        }
        return 'verdictStronger';
    }

    render() {
        super.render();

        if (!this._tooltip) {
            render(this._componentElement, this._hintContainer, 'after');
            this._tooltip = new DynamicPopupHint.Instance({
                title: this.lmsg('title'),
                waitMsg: '',
                url: '',
                placement: 'right',
                target: this._hint,
            });
        }
        this._onChange();
    }
}
