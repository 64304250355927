// Copyright 1999-2021. Plesk International GmbH. All rights reserved.

import { Component } from './component';

export class DisplayField extends Component {
    _initConfiguration(config) {
        super._initConfiguration({
            cls: 'form-row',
            ...config,
        });
        this._valueRenderer = this._getConfigParam('valueRenderer', null);
    }

    renderHint() {
        const hint = this._getConfigParam('hint');

        if (!hint) {
            return '';
        }

        return `<span class="hint">${hint}</span>`;
    }

    _initComponentElement() {
        super._initComponentElement();

        let values = ('function' === typeof this._valueRenderer)
            ? this._valueRenderer()
            : this._getConfigParam('value', '');

        values = (typeof values === 'string') ? [values] : values;

        let valuesElements = '';
        values.each(value => {
            valuesElements += `<div class="text-value">${value}</div>`;
        });

        if (this._getConfigParam('singleRow', false)) {
            if (1 === values.length) {
                valuesElements = values.valueOf();
            }
            this._componentElement.innerHTML = `<div class="single-row">${valuesElements}</div>`;
        } else {
            this._componentElement.innerHTML = (
                `<div class="field-name">${this._getConfigParam('fieldLabel', '')}${this.renderHint()}</div>` +
                `<div class="field-value">${valuesElements}</div>`
            );
        }
    }
}

export class Values {
    /**
     * @cfg {String} selector CSS selector
     */

    constructor(config) {
        this.selector = config.selector;
        this.formValues = {};
        document.querySelectorAll(this.selector).forEach(input => {
            this.formValues[this.getStorageName(input)] = input.value;
        });
    }

    /**
     * @returns {Boolean}
     */
    isChanged() {
        let changed = false;
        document.querySelectorAll(this.selector).forEach(input => {
            if (this.formValues[this.getStorageName(input)] != input.value) {
                changed = true;
            }
        });
        return changed;
    }

    getStorageName(input) {
        return `${input.getAttribute('type')}/${input.getAttribute('name')}`;
    }
}
