// Copyright 1999-2021. Plesk International GmbH. All rights reserved.

import render from './render';
import { Component } from './component';

export class DropdownHeader extends Component {
    _initConfiguration(config) {
        super._initConfiguration({
            cls: 'dropdown-header',
            ...config,
        });
        this._title = this._getConfigParam('title', '');
    }

    _initComponentElement() {
        super._initComponentElement();
        render(this._componentElement, this._title);
    }
}
