// Copyright 1999-2021. Plesk International GmbH. All rights reserved.

import { createElement, Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery, gql } from '@apollo/client';
import Info from './Info';
import Preferences, { OverlayAdapter } from './Preferences';
import { loadPreferences, savePreferences, hasPreferences } from 'common/gdpr-storage';
import usePreferencesToggle from './usePreferencesToggle';
import Cookie from 'common/cookie';
import { useConfigContext } from '../ConfigContext';

const CookiePolicy = ({ layoutType }) => {
    const { gdprCookies } = useConfigContext();
    const [isInfoVisible, setInfoVisible] = useState(() => !hasPreferences());
    const [isPreferencesVisible, setPreferencesVisible] = usePreferencesToggle();
    const [preferences, setPreferences] = useState(loadPreferences);

    const { data: { config } = {} } = useQuery(gql`
        {
            config {
                gdpr {
                    cookieBox {
                        enabled
                    }
                }
            }
        }
    `);

    const handleSave = nextPreferences => {
        setInfoVisible(false);
        setPreferences(nextPreferences);
        savePreferences(nextPreferences);
        Object.keys(gdprCookies).forEach(name => {
            if (nextPreferences[gdprCookies[name]] === false) {
                Cookie.remove(name, '/');
            }
        });
    };

    return (
        <Fragment>
            {config?.gdpr?.cookieBox?.enabled && isInfoVisible && (
                <Info
                    onSave={handleSave}
                    onPreferencesToggle={setPreferencesVisible}
                    contentAlignment={layoutType === 'simplified' ? 'center' : undefined}
                />
            )}
            <Preferences
                isOpen={isPreferencesVisible}
                preferences={preferences}
                onSave={handleSave}
                onToggle={setPreferencesVisible}
                adapter={layoutType === 'simplified' ? OverlayAdapter : undefined}
            />
        </Fragment>
    );
};

CookiePolicy.propTypes = {
    layoutType: PropTypes.oneOf(['simplified']),
};

CookiePolicy.defaultProps = {
    layoutType: undefined,
};

export default CookiePolicy;
