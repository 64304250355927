// Copyright 1999-2021. Plesk International GmbH. All rights reserved.
/* eslint-disable react/jsx-max-depth */

import { createElement } from 'react';

const NotFound = () => (
    <div className="page">
        <div className="main">
            <div className="error-description">
                <h1>{'Server Error'}</h1>
                <div className="error-code">{'404'}</div>
                <h2>{'Page Not Found'}</h2>
                <p className="lead">{'This page either doesn\'t exist, or it moved somewhere else.'}</p>
                <hr />
                <p>
                    {'If you think this is an error, please '}
                    <a href="https://www.plesk.com/bug-report/" target="_blank" rel="noopener noreferrer">{'let us know'}</a>
                    {' so we can fix it!'}
                </p>
                <p>{'That\'s what you can do'}</p>
                <div className="help-actions">
                    <a href="javascript:location.reload();">{'Reload Page'}</a>
                    <a href="javascript:history.back();">{'Back to Previous Page'}</a>
                    <a href="/">{'Home Page'}</a>
                </div>
            </div>
            <div className="help-links">
                <a href="https://www.plesk.com/bug-report/" className="help-link" title="Report a problem" target="_blank" rel="noopener noreferrer">
                    <div className="icon report">
                        <svg>
                            <use xlinkHref="/error_docs/symbols.svg#report" />
                        </svg>
                    </div>
                </a>
                <a href="https://docs.plesk.com/" className="help-link" title="Documentation" target="_blank" rel="noopener noreferrer">
                    <div className="icon documentation">
                        <svg>
                            <use xlinkHref="/error_docs/symbols.svg#documentation" />
                        </svg>
                    </div>
                </a>
                <a href="https://kb.plesk.com/" className="help-link" title="Knowledge base" target="_blank" rel="noopener noreferrer">
                    <div className="icon knowledge-base">
                        <svg>
                            <use xlinkHref="/error_docs/symbols.svg#knowledge-base" />
                        </svg>
                    </div>
                </a>
                <a href="https://talk.plesk.com/" className="help-link" title="Forum" target="_blank" rel="noopener noreferrer">
                    <div className="icon forum">
                        <svg>
                            <use xlinkHref="/error_docs/symbols.svg#forum" />
                        </svg>
                    </div>
                </a>
                <a href="https://www.youtube.com/channel/UCeU-_6YHGQFcVSHLbEXLNlA" className="help-link" title="YouTube" target="_blank" rel="noopener noreferrer">
                    <div className="icon youtube">
                        <svg>
                            <use xlinkHref="/error_docs/symbols.svg#youtube" />
                        </svg>
                    </div>
                </a>
                <a href="https://www.facebook.com/Plesk" className="help-link" title="Facebook" target="_blank" rel="noopener noreferrer">
                    <div className="icon facebook">
                        <svg>
                            <use xlinkHref="/error_docs/symbols.svg#facebook" />
                        </svg>
                    </div>
                </a>
            </div>
        </div>
    </div>
);

export default NotFound;
