// Copyright 1999-2021. Plesk International GmbH. All rights reserved.

/**
 * Temporary solution for fire custom events
 */
export default (element, eventName) => {
    if (document.createEvent) {
        const event = document.createEvent('HTMLEvents');
        event.initEvent('dataavailable', true, true);
        event.eventName = eventName;

        element.dispatchEvent(event);
        return;
    }

    const event = document.createEventObject();
    event.eventType = 'ondataavailable';
    event.eventName = eventName;

    element.fireEvent(event.eventType, event);
};
