// Copyright 1999-2021. Plesk International GmbH. All rights reserved.

import { createElement } from 'react';
import { gql, useQuery } from '@apollo/client';
import { Locale } from 'jsw';
import { useConfigContext } from '../ConfigContext';
import { usePreferencesToggle } from '../CookiePolicy';

const Translate = Locale.getTranslate('components.shortcuts');

const PageFooter = () => {
    const config = useConfigContext();
    const [, toggleCookiePolicy] = usePreferencesToggle();
    const { data: { viewer: user } = {} } = useQuery(gql`
        {
            viewer {
                type
            }
        }
    `);

    const handleProvideRatingClick = () => {
        Plesk.require('app/rating', rating => {
            rating({
                dialogCause: ' manual',
                supportUrl: config.product.supportUrl,
            });
        });
    };

    return (
        <div className="page-footer" data-type="page-footer">
            {config.promos.pleskFooter ? (
                <a href={config.product.siteUrl} target="_blank" rel="noopener noreferrer">{'plesk.com'}</a>
            ) : null}
            {user?.type === 'ADMIN' && config.facebook.showLikeLink ? (
                <div className="social-actions">
                    <a className="fb-link" href={config.facebook.pleskPage} target="_blank" rel="noopener noreferrer">
                        <svg className="icon" id="facebook" viewBox="0 0 32 32">
                            {/* eslint-disable-next-line max-len */}
                            <path d="M17.906,9.9V7.012a1.662,1.662,0,01,1.776-1.546h3.094V-0.052l-4.26-.022c-4.736,0-5.579,4.113-5.579,6.746L13,9.9H9.97v6.123h2.96v16.1H17.9v-16.1h4.447l0.2-2.53,0.3-3.593H17.906" />
                        </svg>
                        <Translate content="facebookJoinCommunity" />
                    </a>
                </div>
            ) : null}
            {user?.type === 'ADMIN' && config.twitter.showFollowLink ? (
                <div className="social-actions">
                    <a className="twitter-follow-button" href={config.twitter.pleskPage} target="_blank" rel="noopener noreferrer">
                        <svg className="icon" viewBox="0 0 72 72">
                            {/* eslint-disable-next-line max-len */}
                            <path fill="#fff" d="M68.812 15.14c-2.348 1.04-4.87 1.744-7.52 2.06 2.704-1.62 4.78-4.186 5.757-7.243-2.53 1.5-5.33 2.592-8.314 3.176C56.35 10.59 52.948 9 49.182 9c-7.23 0-13.092 5.86-13.092 13.093 0 1.026.118 2.02.338 2.98C25.543 24.527 15.9 19.318 9.44 11.396c-1.125 1.936-1.77 4.184-1.77 6.58 0 4.543 2.312 8.552 5.824 10.9-2.146-.07-4.165-.658-5.93-1.64-.002.056-.002.11-.002.163 0 6.345 4.513 11.638 10.504 12.84-1.1.298-2.256.457-3.45.457-.845 0-1.666-.078-2.464-.23 1.667 5.2 6.5 8.985 12.23 9.09-4.482 3.51-10.13 5.605-16.26 5.605-1.055 0-2.096-.06-3.122-.184 5.794 3.717 12.676 5.882 20.067 5.882 24.083 0 37.25-19.95 37.25-37.25 0-.565-.013-1.133-.038-1.693 2.558-1.847 4.778-4.15 6.532-6.774z" />
                        </svg>
                        <Translate content="twitterFollow" />
                    </a>
                </div>
            ) : null}
            {user?.type === 'ADMIN' && config.rating.enabled ? (
                <div className="feedback-actions">
                    <a id="provide-rating-button" className="s-btn" onClick={handleProvideRatingClick}>
                        <i className="icon-opinions" /> <Translate content="provideRating" />
                    </a>
                </div>
            ) : null}
            {config.feedback.userVoice ? (
                <div className="feedback-actions">
                    <a className="s-btn sb-feedback" href={config.feedback.userVoiceUrl} target="_blank" rel="noopener noreferrer">
                        <span><Translate content="userFeedback" /></span>
                    </a>
                </div>
            ) : null}
            <div className="feedback-actions">
                <a onClick={() => toggleCookiePolicy(true)}>
                    <Translate content="linkToCookies" />
                </a>
            </div>
        </div>
    );
};

export default PageFooter;
