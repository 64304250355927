// Copyright 1999-2021. Plesk International GmbH. All rights reserved.

import { createElement } from 'react';
import PropTypes from 'prop-types';
import { Form, Heading } from '@plesk/ui-library';
import Overlay from './Overlay';

const OverlayAdapter = ({
    isOpen,
    onSubmit,
    onCancel,
    submitButton,
    cancelButton,
    title,
    children,
    values,
}) => (
    <Overlay isOpen={isOpen} onCancel={onCancel}>
        <Form
            vertical
            onSubmit={onSubmit}
            onCancel={onCancel}
            applyButton={false}
            submitButton={submitButton}
            cancelButton={cancelButton}
            values={values}
        >
            <Heading level={1} className="cookie-policy-preferences__title">
                {title}
            </Heading>
            {children}
        </Form>
    </Overlay>
);

OverlayAdapter.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    submitButton: PropTypes.shape({
        children: PropTypes.node.isRequired,
    }).isRequired,
    cancelButton: PropTypes.shape({
        children: PropTypes.node.isRequired,
        onClick: PropTypes.func.isRequired,
    }).isRequired,
    title: PropTypes.node.isRequired,
    children: PropTypes.node.isRequired,
    values: PropTypes.object.isRequired,
};

export default OverlayAdapter;
